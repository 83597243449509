<template>
  <v-row class="ma-0">
    <v-col cols="12" class="d-flex justify-center align-center">
      <v-card outlined class="pa-6" style="width: 90%" rounded="lg">
        <div class="d-flex justify-center align-center float-right">
          <v-btn small text color="primary" :to="{path:'/chart'}">
            {{ $t("$vuetify.language.bar_info[1]") }}
          </v-btn>

          <v-btn small text color="primary" :to="{path:'/results'}">
            {{ $t("$vuetify.language.bar_info[3]") }}
          </v-btn>
          <v-btn small text color="primary" @click="changeLanguage">
            {{$t("$vuetify.language.bar_info[0]") }}
          </v-btn>

          <v-icon class="mx-2">mdi-theme-light-dark</v-icon>
          <v-switch @change="changeTheme" v-model="$vuetify.theme.dark"></v-switch>
        </div>

        <v-card-text>
          <v-img :src="require('../../assets/images/sdc_logo_landscape.png')" width="180"></v-img>
          <div class="text-h5 font-weight-medium mt-3">
            {{ $t("$vuetify.language.sitename") }}
          </div>
          {{ $t("$vuetify.language.subtitle1") }}
          <router-view></router-view>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Index',
  methods:{
    changeLanguage(){
      if (this.$i18n.locale === 'en'){
        this.$i18n.locale = 'cn';
      } else {
        this.$i18n.locale = 'en'
      }
    },
    changeTheme() {
      this.$store.commit("changeTheme", this.$vuetify.theme.dark);
    },
  }
}
</script>

<style scoped>

</style>
